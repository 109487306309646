* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: black;
  font-family: "Cinzel", serif;
}

.app {
  padding: 2rem;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;

  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.current {
  z-index: 1;
}

.next {
  z-index: 0;
  transform: translateY(100%);
}

.slide-out-left {
  animation: slideOutLeft 1s forwards;
}

.slide-in-right {
  animation: slideInRight 1s forwards;
}

@keyframes slideOutLeft {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideInRight {
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(0);
  }
}

.leftSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
  width: 100%;
  gap: 2rem;
  max-width: 500px;
}
.headBut {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3rem;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 1rem;
}
.next-text,
.next-text2 {
  display: flex;
}
#headText {
  font-size: 4rem;
}

#headText2 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2rem;
}
#headText3 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2rem;
}

.text-wrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -8rem;
}

.text {
  font-size: 2rem;
  font-weight: lighter;
  color: white;
  text-align: center;
  z-index: 11;
}

.button-wrapper {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem; /* Add margin for spacing */
}

button {
  z-index: 11; /* Ensure the button is above everything */
  height: 3rem;
  background-color: gray;
  display: flex;
  align-items: center;
  font-weight: bolder;
  color: white;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  padding: 1rem 2rem 1rem 2rem;
  font-size: 1rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

button:hover {
  background-color: #005f5f;
}

.cards {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 3;
}

.card {
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
  transition: transform 3s ease-in-out;
}

.card:nth-child(1) {
  height: 19rem;
  width: 17rem;
}

.card:nth-child(2) {
  height: 17rem;
  width: 15rem;
}

.card:nth-child(3) {
  height: 15rem;
  width: 13rem;
}

@keyframes slideAndFlip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.rotate {
  animation: slideAndFlip 1.5s forwards;
}
.explore-wrapper {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.explore-button {
  z-index: 11; /* Ensure the button is above everything */
  height: 3rem;
  background-color: gray;
  display: flex;
  align-items: center;
  font-weight: bolder;
  color: white;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  padding: 1rem 2rem 1rem 2rem;
  font-size: 1rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.explore-button:hover {
  background-color: #005f5f;
}

@media (max-width: 768px) {
  .app{
    display: flex;
    flex-direction: column;
  }
  .leftSide {
    padding: 1rem;
    max-width: 100%;
    align-items: center;
  }
  
  .text {
    font-size: 1.5rem;
  }

  .headBut {
    display: flex;
    flex-direction: column;
  }

  .card:nth-child(1) {
    height: 18rem;
    width: 15rem;
  }

  .cards {
    margin-bottom: 3rem;
  }

  .button-wrapper{
    margin-left: 0rem;
  }

  .card:nth-child(2) {
    height: 16rem;
    width: 13rem;
  }

  .card:nth-child(3) {
    height: 14rem;
    width: 11rem;
  }
  .text-wrapper2 {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .leftSide {
    align-items: center;
    padding: 0.5rem;
  }

  .text {
    font-size: 1.2rem;
  }

  button {
    height: 2.5rem;
    font-size: 0.8rem;
  }

  .card:nth-child(1) {
    height: 16rem;
    width: 13rem;
  }

  .card:nth-child(2) {
    height: 14rem;
    width: 11rem;
  }

  .card:nth-child(3) {
    height: 12rem;
    width: 9rem;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}