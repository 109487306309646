.carousel {
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
}
.carousel-container {
  position: relative;
  width: 600px; /* Increased width */
  height: 550px; /* Increased height */
  perspective: 1000px; /* Increased perspective */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}

.carousel-item {
  position: absolute;
  width: 250px;
  height: 450px;
  background-size: cover;
  background-position: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
  border-radius: 15px;
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); */
}

.carousel-controls {
  /* position: absolute; */
  display: flex;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.carousel-button {
  background: transparent;
  box-shadow: none;
  color: white;
  /* border: none;
  border-radius: 50%; */
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}
.carousel-button {
  padding-top: 5rem;
  font-size: 3rem;
}
.carousel-button:hover {
  background: transparent;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

@media screen and (max-width: 748px) {
  .carousel {
    width: 80%;
    margin: 0 auto;
    /* overflow: hidden; */
  }
  .carousel-container {
    position: relative;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-wrap: normal;
    perspective: 800px;
  }
  .carousel-item {
    width: 60%;
    margin: 0 auto;
    position: absolute;
  }
}

@media screen and (min-width: 748px) and (max-width: 1024px) {
  .carousel-container {
    width: 450px; /* Increased width */
    height: 280px;
  }
  .carousel-item {
    position: absolute;
    width: 350px;
    height: 180px;
  }
}
