* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "cinzel", serif;
}
nav {
  z-index: 1000;
  width: 100%;
  background-color: rgb(61, 37, 114);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 7rem;
    height: 5rem;
  }
  padding-bottom: 4rem;
}
.marketingIntro {
  animation: slideUp 2s ease-out;
  color: white;
  background: linear-gradient(
    180deg,
    rgb(61, 37, 114) 5%,
    rgba(26, 26, 26) 15%
  );
  height: 100%;
  /* padding-top: 4rem; */
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.markingSubIntro {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}
.markertingHeading {
  font-size: 2rem;
  line-height: 1.5rem;
}
.markHeading {
  font-weight: 100;
  font-size: 4vw;
  line-height: 4vw;
  font-variation-settings: "wght" 100, "wdth" 85;
  color: white;
}
.markHeading .char {
  --delay: calc((var(--char-index) + 1) * 100ms);
  animation: breathe 4000ms infinite both;
  animation-delay: var(--delay);
}

@keyframes breathe {
  0% {
    font-variation-settings: "wght" 100, "wdth" 85;
    font-size: 4vw;
  }
  60% {
    font-variation-settings: "wght" 700, "wdth" 100;
    font-size: 5vw;
  }
  100% {
    font-variation-settings: "wght" 100, "wdth" 85;
    font-size: 4vw;
  }
}
.marketingIntroHead {
  display: flex;
  padding-top: 7rem;
  display: flex;
  width: 95%;
  margin: 0 auto;
}
.introHead1 {
  display: flex;
  flex-direction: column;
  gap: 0;
  font-size: 3rem;
  flex: 1;
  align-items: center;
}
.introHead1a {
  font-size: 1rem;
  color: #808080;
}
.introHead2 {
  font-size: 4rem;
  flex: 3;
  display: flex;
  justify-content: center;
}
.marketingIntroDesc {
  width: 90%;
  margin: 0 auto;
  padding-top: 5rem;
}
.introDesc1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 1rem;
  padding-top: 5rem;
}
.introDesc1a {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.introDesc1a > button {
  font-weight: bolder;
  background-color: white;
  color: black;
  border-radius: 1.5rem;
  border-width: 0rem;
  padding: 1rem 2rem 1rem 2rem;
  width: fit-content;
}
.introDesc1b {
  padding-top: 1rem;
  color: white;
  flex: 2;
  padding-right: 2rem;
}

.services {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.servicesHead {
  display: flex;
  width: 90%;
  margin: 0 auto;
}
.servicesHeading {
  flex: 1;
  color: white;
  font-size: 1rem;
  text-align: center;
}
.servicesTagline {
  flex: 2;
  color: white;
  font-weight: lighter;
  font-size: 3rem;
}
.servicesDesc {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  gap: 1rem;
  margin-top: 3rem;
}
.servicesCards {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 90%;
  margin: 0 auto;
  margin-top: 3rem;
}
.servicesCards1 {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}
.servicesCards2 {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}
.services1 {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: rotate(-10deg);
  padding: 0rem 2rem 0rem 2rem;
  background: linear-gradient(
    135deg,
    rgb(61, 37, 114) 10%,
    rgba(26, 26, 26) 80%
  ); /* Colors from top left to bottom right */
  width: 23rem;
  height: 17rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.services2 {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: rotate(10deg);
  padding: 0rem 2rem 0rem 2rem;
  background: linear-gradient(
    225deg,
    rgb(61, 37, 114) 10%,
    rgba(26, 26, 26) 80%
  );
  width: 23rem;
  height: 17rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.services1 > img {
  width: 20rem;
}
.services2 > img {
  width: 20rem;
}
.videosMain {
  display: flex;
  flex-direction: column;
  padding-top: 7rem;

  background: radial-gradient(
    ellipse at center,
    rgba(26, 26, 26) 70%,
    rgb(61, 37, 114) 100% /* rgba(26, 26, 26) 30% */
  );
  height: 100%;
  width: auto;
}
.videosHead {
  display: flex;
  width: 90%;
  margin: 0 auto;
}
.videosHeading {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  align-items: center;
  flex: 1;
  color: white;
}
.nav-co {
  color: white;
  font-size: 0.9rem;
}
.videosTagline {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  font-size: 3rem;
  color: white;
}
.videos {
  padding-top: 8rem;
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.videos1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  justify-content: center;
  flex: 1;
}

.videos2 {
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 5rem;
}

.small-video {
  height: 30rem;
  width: auto;
}

.grey {
  color: white;
  font-weight: lighter;
  font-size: 0.8rem;
}

.greys {
  display: flex;
  justify-content: space-between;
}
.case {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.case > button {
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 1rem 10rem 1rem 10rem;
  border-radius: 2rem;
}
.contact {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: none;
  border-right: none;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.contact1 {
  padding-left: 3rem;
  padding-right: 3rem;
  color: white;
  font-size: 3rem;
  font-weight: 600;
  flex: 2;
}

.contact2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.contact2 > button {
  font-weight: bolder;
  border-radius: 1.5rem;
  border-width: 0rem;
  padding: 1rem 2rem 1rem 2rem;
}

.footer {
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.footer > div > div > h1 {
  color: white;
}
@media (max-width: 748px) {
  .markHeading {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  .introHead1 {
    font-size: 1rem;
  }
  .introHead2 {
    font-size: 1.5rem;
  }
  .introDesc1 {
    flex-direction: column-reverse;
  }
  .servicesHead {
    flex-direction: column;
    text-align: center;
  }
  .servicesCards1 {
    flex-direction: column;
    align-items: center;
  }
  .servicesCards2 {
    flex-direction: column;
    align-items: center;
  }
  .services1,
  .services2 {
    width: 18rem;
  }
  .services1 > img,
  .services2 > img {
    width: 15rem;
  }
  .videosHead {
    flex-direction: column;
  }
  .videos {
    flex-direction: column;
    gap: 2rem;
  }
  .videos1 {
    flex-direction: column;
    margin-bottom: 8rem;
  }
  .videos2 {
    flex-direction: column;
  }
  .contact1 {
    font-size: 1rem;
  }
}
@media screen and (min-width:548px) and (max-width:748px) {

}
